<template>
  <div @contextmenu="openContextMenu($event)">
    <apa-sidebar :user="currentUser">
      <template slot="links">
        <apa-sidebar-link :name="$t('message.dashboard')" icon="fas fa-home" path="/my-dashboard" />
        <apa-sidebar-link :name="$t('message.projects')" icon="ni ni-ungroup" path="/projects"
          v-if="currentUserRights.includes('Alle Projekte_')" />
        <!--
        <apa-sidebar-link :name="$t('message.tasks')" icon="ni ni-bullet-list-67" path="/tasks"
          v-if="currentUserRights.includes('Dashboard Projekt A_ToDo´s (projektbezogen) ')" />
        -->
        <apa-sidebar-link :name="$t('message.admin')" icon="fas fa-cog" path="/admin"
          v-if="currentUserRights.includes('Administration_')" />
        <apa-sidebar-link :name="$t('message.breadcrumb.logistic')" icon="fas fa-truck" path="/logistic"
          v-if="currentUserRights.includes('logistic-view.cansee')" />
      </template>
      <dashboard-navbar></dashboard-navbar>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <router-view></router-view>
      </fade-transition>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      <!--
      <vue-context ref="menu" v-if="currentUserRights.includes('im.comment.cancreatetask')">
        <li>
          <a @click.prevent="addTaskFromSelection()">
            {{ $t('task.new_task_from_selection') }}
          </a>
        </li>
      </vue-context>
      -->
    </apa-sidebar>
    <task-form :show.sync="showForm" :task="task" 
      v-if="task" />
    
    <modal class="connection" :show="showModal" size="lg">
      <h6 slot="header" class="modal-title">
        <i class="fa fa-exclamation-triangle mr-3"></i>Offline
      </h6>
      <div>Die Netzwerkverbindung wurde unterbrochen.</div>
      <div>Sobald das Gerät wieder verbunden ist, wird diese Meldung geschlossen.</div>
    </modal>

  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import { mapGetters, mapActions } from "vuex";

import ApaSidebar from "@/components/ApaSidebar/ApaSidebar";
import ApaSidebarLink from "@/components/ApaSidebar/ApaSidebarLink";
// import TaskForm from "@/components/Tasks/TaskForm";

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import createTask from "@/store/modules/tasks/createTask";

// import VueContext from 'vue-context';
// import 'vue-context/src/sass/vue-context.scss';

import refreshNC from '@/util/nextcloud';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

export default {
  components: {
    ApaSidebar,
    ApaSidebarLink,
    // TaskForm,
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition,
    // VueContext
  },
  computed: {
    ...mapGetters([
      "currentUser",
      'currentUserRights',
    ])
  },
  data() {
    return {
      isVisible: false,
      selectedText: "",
      task: null,
      showForm: false,
      showModal: false
    };
  },
  mounted() {
    this.connectionCheck();
    this.$store.dispatch("findRoles");
    this.$store.dispatch('refreshCurrentUser');
    this.$store.dispatch("findVehicles");
    this.$store.dispatch("loadAllProjects");
    this.$store.dispatch("loadUsers");
    // this.$store.dispatch("findTasks");
    this.$store.dispatch("findCustomers");
    refreshNC();

    // this.$store.dispatch("loadTasks", {
    //   filter: {
    //     user_id: this.currentUser.id
    //   }
    // })
    // .then(tasks => this.$store.commit('userTasks', tasks))

    // this.loadNotifications()
    // this.notificationUpdateTimer = setInterval(this.loadNotifications, 60000)
  },
  methods: {
    toggleMenu() {
      this.$refs["user-dropdown"].classList.toggle("show");
      this.isVisible = true;
    },
    logout() {
      this.$auth.logout();
    },
    openContextMenu(e) {
      this.selectedText = window.getSelection().toString();
      if (this.selectedText !== '') {
        this.$refs.menu.open(e);
        e.preventDefault();
      }
    },
    addTaskFromSelection() {
      if (this.selectedText !== '') {
        const { project_id } = this;

        const data = createTask({
          title: '',
          project_id,
          user_id: this.currentUser.id
        })

        if (this.selectedText !== '') {
          data.title = this.selectedText;
          this.selectedTextHandled = true
        }


        this.task = { ...data };
        this.showForm = true;
        // this.$router.push({name: `tasks`, params: { selectedText: this.selectedText.substring(0, 50) }});
      }
    },
    loadNotifications() {
      this.$store.dispatch("getNotifications", {
        user: this.currentUser
      }).then(notifications => {
        this.$store.commit('globalNotifications', notifications)
      })
    },
    connectionCheck() {
      // Check you are online or not
      // if (navigator.onLine) {
      //   console.log("online");
      // } else {
      //   console.log("offline");
      // }

      // To see changes in the network state, use addEventListener
      window.addEventListener("offline", (e) => {
        this.showModal = true;
        // this.$notify({
        //   verticalAlign: 'top',
        //   horizontalAlign: 'right',
        //   message: 'Oh nein! Offline.',
        //   type: 'error',
        //   icon: 'fa fa-exclamation-triangle',
        //   timeout: 10000
        // })
      
      });

      window.addEventListener("online", (e) => {
        fetch('/favicon.ico', {mode: 'no-cors'})
          .then(response => {
            console.log('fetch response', response)
            // Check if the response is successful
            if (!response.ok)
              throw new Error('Network response was not ok');

              // At this point we can safely say the user has connection to the internet
                      console.log("Internet available"); 
            })
            // .catch(error => {
            // // The resource could not be reached
            //       console.log("No Internet connection", error);
            // });
        this.showModal = false;
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Wieder online.',
          type: 'success',
          icon: 'fa fa-wifi',
          timeout: 3000
        })
      });
    }
  }
};
</script>
<style>
.modal.connection .modal-title,
.modal.connection .modal-content {
  color: #f7f9fc;
}
.modal.connection .modal-content {
  min-height: 300px;
  background-color: #0a0c14;
}
.modal.connection .modal-header {
  padding-left: 1.5rem;;
  border-bottom: 1px solid #4c5159;
}
.modal.connection .modal-header .close {
  display: none;
}
.modal.connection .modal-header .close > span {
  color: #4c5159;
  border: 1px solid #4c5159;
  padding-left: 1px;
}
.modal.connection .modal-body {
  line-height: 2em;
}
</style>